import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BnbKeys } from "@fruitsjs/core/src";
import { BEP20ABI } from "app/util/BEP20ABI";
import { environment } from "environments/environment";
import { BlockTransactionObject, FeeHistoryResult } from 'web3-eth';
import { constants } from '../constants';
import { SignedTransaction } from 'web3-core';
import { BroadcastData } from "./btc.service";
import { TransactionStatus } from "app/main/btc-transaction/transaction-table/btc-transaction-table.component";

const Web3 = require('web3');

const HDWalletProvider = require('@truffle/hdwallet-provider');
const web3 = new Web3(new Web3.providers.HttpProvider(environment.bnbUrl));

@Injectable({
    providedIn: 'root'
})
export class BnbService {

    constructor(private http: HttpClient) {

    }
    usdtAddress = environment.usdtBnbAddress;

    multiCoinUrl = environment.isMainNet ? constants.coinMarketCapURL : constants.multicoinTestURL;

    async generateKey(passphrase): Promise<BnbKeys> {
        try {
            const walletProvider = new HDWalletProvider({
                mnemonic: {
                    phrase: passphrase
                },
                numberOfAddresses: 1,
                providerOrUrl: environment.bnbUrl
            });
            walletProvider.engine;
            const address = walletProvider.getAddresses();
            walletProvider.engine.stop();
            return {
                address: address[0],
                privateKey: walletProvider.wallets[address].getPrivateKeyString(),
                balance: await this.getBalance(address[0]),
                usdtBalance: await this.getUSDTBalance(address[0])
            };
        } catch (e) {
            console.log('e', e);
            return undefined;
        }
    }

    async getBalance(address): Promise<string> {
        const balance = await web3.eth.getBalance(address);
        return balance ? web3.utils.fromWei(balance, 'ether') : '0';
    }

    async getUSDTBalance(accountAddress: string): Promise<string> {
        const tokenContract = new web3.eth.Contract(BEP20ABI, this.usdtAddress);
        const usdtBalance = await tokenContract.methods.balanceOf(accountAddress).call();
        return usdtBalance ? web3.utils.fromWei(usdtBalance, 'ether') : '0';
    }

    async getGasPrice(): Promise<string> {
        const gas = await web3.eth.getGasPrice();
        return gas ? web3.utils.fromWei(gas, 'ether') : '0';
    }

    async getLatestBlock(): Promise<BlockTransactionObject> {
        return await web3.eth.getBlock('pending');
    }

    public isValidAddress(address): boolean {
        return web3.utils.isAddress(address);
    }

    public toGwei(gas): string {
        return web3.utils.fromWei(gas, 'gwei');
    }

    public toEther(amount): string {
        return web3.utils.fromWei(amount, 'ether');
    }
    async getFeeHistory(): Promise<FeeHistoryResult> {
        const feeHistory = await web3.eth.getFeeHistory(constants.historicalBlocksBnb, 'latest', [25, 50, 75]);
        const filteredRewards = feeHistory.reward.filter(rewardArray => 
            !rewardArray.includes("0x0")
        );
        return {
            ...feeHistory,
            reward: filteredRewards,
        };
    }

    async sendAmount(data): Promise<SignedTransaction> {
        return await web3.eth.accounts.signTransaction({
            from: data.from,
            to: data.to,
            value: web3.utils.toWei(data.amount, 'ether'),
            gas: data.gasLimit,
            maxFeePerGas: data.maxFeeGas,
            maxPriorityFeePerGas: data.maxPriority,
            nonce: data.nonce
        }, data.privateKey);
    }

    async sendUSDT(data: any): Promise<SignedTransaction> {
        const tokenContract = new web3.eth.Contract(BEP20ABI, this.usdtAddress);
        const transfer = tokenContract.methods.transfer(data.toAddress, data.amount);
        const encodedABI = transfer.encodeABI();
        return await web3.eth.accounts.signTransaction({
          to: this.usdtAddress,
          data: encodedABI,
          gas: data.gas,
          gasPrice: data.gasPrice,
        }, data.privateKey);
    }

    getTransactionByAddress = async (address) => {
        const url = `${this.multiCoinUrl}/fruits/multi-coin/binance/transactions?address=${address}`;
        const response = await fetch(url, {
            method: 'GET'
        });
        return await response.json();
    }

    confirmedTransactions = async (address) => {
        const url = `${this.multiCoinUrl}/fruits/multi-coin/api/confirmed-transactions?address=${address}&blockchain=${constants.bnbNetwork}&network=${environment.isMainNet ? constants.mainnet : constants.testnet}`;
        const response = await fetch(url, {
            method: 'GET'
        });
        return await response.json();
    }

    unconfirmedTransactions = async (address) => {
        const url = `${this.multiCoinUrl}/fruits/multi-coin/api/unconfirmed-transactions?address=${address}&blockchain=${constants.bnbNetwork}&network=${environment.isMainNet ? constants.mainnet : constants.testnet}`;
        const response = await fetch(url, {
            method: 'GET'
        });
        return await response.json();
    }

    transactionsStatus = async (address: string, coinType: string) => {
        const url = `${this.multiCoinUrl}/fruits/multi-coin/api/transactions-status?address=${address}&coinType=${coinType}`;
        const response = await fetch(url, {
            method: 'GET'
        });
        return await response.json();
    }

    public getTransactionCount(address): Promise<number> {
        return web3.eth.getTransactionCount(address, 'pending');
    }
    
    public hexToNumber(hex): number {
        return web3.utils.hexToNumber(hex);
    }

    broadcastTransaction = async (broadcastData) => {
        const url = `${this.multiCoinUrl}/fruits/multi-coin/binance/broadcast-tx`;

        const myHeaders = new Headers();
        myHeaders.append('Content-Type', 'application/json');
        myHeaders.append('Accept', 'application/json');

        const response = await fetch(url, {
            method: 'POST',
            headers: myHeaders,

            body: JSON.stringify({
                signedTx: broadcastData.signedTx
            })
        });

        return await response.json();
    }

    async estimateGas(data: any): Promise<any> {
        if (!(data.to && this.isValidAddress(data.to) && data.amount) || data.amount.indexOf('.') !== -1) {
            return new Promise((resolve) => {
                resolve(0);
            });
        }
        const tokenContract = new web3.eth.Contract(BEP20ABI, this.usdtAddress);
        return tokenContract.methods.transfer(data.to, data.amount).estimateGas({ from: data.from });
    }
    
    async getTokenTransaction(address: string): Promise<any> {
        return this.http.get(`${this.multiCoinUrl}/fruits/multi-coin/binance/token-transactions?address=${address}&tokenAddress=${this.usdtAddress}`).toPromise();
    }

    async getEstGasFees(): Promise<any> {
        return this.http.get(`${this.multiCoinUrl}/fruits/multi-coin/binance/suggested-gas-fees`).toPromise();
    }
    
}

export interface TokenTransaction {
    blockNumber: string;
    timeStamp: string;
    hash: string;
    nonce: string;
    blockHash: string;
    from: string;
    contractAddress: string;
    to: string;
    value: string;
    tokenName: string;
    tokenSymbol: string;
    tokenDecimal: string;
    transactionIndex: string;
    gas: string;
    gasPrice: string;
    gasUsed: string;
    cumulativeGasUsed: string;
    input: string;
    confirmations: string;
    fee: string;
    status: TransactionStatus;
  }
  