import {Component, Inject, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Title} from '@angular/platform-browser';
import {constants} from '../../../constants';
import {NotifierService} from 'angular-notifier';
import {I18nService} from '../../../layout/components/i18n/i18n.service';
import * as moment from 'moment';
import {environment} from '../../../../environments/environment';
import {Amount, ChainTime} from '@fruitsjs/util';
import {CoinType} from '../../../shared/pipes/coin-amount.pipe';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {CancelOrderEthComponent} from '../juice-eth-history/cancel-order-eth/cancel-order-eth.component';
import {isKeyDecryptionError} from '../../../util/exceptions/isKeyDecryptionError';
import {StoreService} from '../../../store/store.service';
import {SwapService} from '../../swap.service';
import {CancelOrderBtcComponent} from '../juice-btc-history/cancel-order-btc/cancel-order-btc.component';
import {Account, ChainService, signAndBroadcastTransaction} from '@fruitsjs/core';
import {CancelTfstsOrderComponent} from '../juice-tfsts-history/cancel-tfsts-order/cancel-tfsts-order.component';
import {decryptAES, hashSHA256, Keys} from '@fruitsjs/crypto';
import {KeyDecryptionException} from '../../../util/exceptions/KeyDecryptionException';
import {takeUntil} from 'rxjs/operators';
import {UnsubscribeOnDestroy} from '../../../util/UnsubscribeOnDestroy';
import {formatDate} from "@angular/common";
import { formatNumber } from 'app/util/util';

@Component({
  selector: 'app-swap-order-details',
  templateUrl: './swap-order-details.component.html',
  styleUrls: ['./swap-order-details.component.scss']
})
export class SwapOrderDetailsComponent extends UnsubscribeOnDestroy implements OnInit {

  public node;
  public locale;
  selectedAccount: Account;
  isLoading = false;
  blockstreamUrl = environment.blockstreamUrl;
  etherscanUrl = environment.etherscanUrl;
  tronscanUrl = environment.tronscanUrl;
  bscScanUrl = environment.bscScanUrl;
  order = null;
  orderDetail = null;
  tfstsOrder;
  url: string;
  isExternalCoin = true;
  public unSubscriber = takeUntil(this.unsubscribeAll);
  tokenName = {
    USDT_TRC: 'USDT (TRC20)',
    USDT_ERC: 'USDT (ERC20)',
    USDT_BEP: 'USDT (BEP20)',
  };

  currencyMapping = {
    USDT_TRC: 'USDT',
    USDT_ERC: 'USDT',
    USDT_BEP: 'USDT',
  }

  networkFeeMapping = {
    USDT_TRC: 'TRX',
    USDT_ERC: 'ETH',
    USDT_BEP: 'BNB',
  }

  constructor(private route: ActivatedRoute,
              private router: Router,
              private storeService: StoreService,
              private notifierService: NotifierService,
              private i18nService: I18nService,
              private warnDialog: MatDialog,
              private swapService: SwapService,
              private title: Title,
              @Inject(MAT_DIALOG_DATA) public data: any) {
    super();
    this.title.setTitle(constants.pageTitle + 'Swap Order Details');
  }

  async ngOnInit(): Promise<void> {
    this.selectedAccount = await this.storeService.getSelectedAccount();
    if (this.data.type === CoinType.frts) {
      this.tfstsOrder = await this.swapService.getTFSTsOrderDetails(this.data.result.id, this.selectedAccount.account);
      this.order = this.tfstsOrder.result;
      this.orderDetail = this.order.detail.sort(function (x, y) {
        if (x.completeDate == null || y.completeDate == null) {
          return -1;
        }
        return x.completeDate - y.completeDate;
      });
      this.isExternalCoin = false;

    } else {
      this.order = this.data.result;
    }

    this.storeService.getSettings().then((result: any) => {
      if (result && result.node) {
        this.node = result.node;
      }
    });
    this.storeService.settings
      .pipe(
        takeUntil(this.unsubscribeAll)
      )
      .subscribe(({language}) => {
        this.locale = language;
      });
  }

  getTotalAmount(asset): string {
    if (!asset) return '0';
    const networkFee = asset.networkFee ? Amount.fromPlanck(asset.networkFee).getRaw() : 0;
    const totalAmount = Amount.fromPlanck(asset.total).getRaw().plus(Amount.fromPlanck(asset.swapFee).getRaw());

    if (asset.type.source === 'USDT_TRC' || asset.type.source === 'USDT_ERC' || asset.type.source === 'USDT_BEP') {
      const formattedTotal = formatNumber(totalAmount.toFixed().toString());
      const formattedNetworkFee = formatNumber(networkFee.toString());
      return `${formattedTotal} USDT + ${formattedNetworkFee} ${this.networkFeeMapping[asset.type.source] || asset.type.source}`;
    } else {
      const totalAmountAndFee = totalAmount.plus(networkFee);
      const formattedTotalAndFee = formatNumber(totalAmountAndFee.toString());
      return `${formattedTotalAndFee} ${asset.type.source}`;
    }
  }

  convertTimestamp(timestamp): any {
    return moment.unix(timestamp).format('lll');
  }

  getLabel(): string {
    return this.url.includes('btc') ? 'swap_history_btc' : 'swap_history_eth';
  }

  getPath(): string {
    return this.url.includes('btc') ? '/juice-btc-history' : '/juice-eth-history';
  }

  toPlainString(num): string {
    return num.replace(/([0-9]+(\.[0-9]+[1-9])?)(\.?0+$)/, '$1');
  }

  cancelOrder(order): void {
    const dialogRef = this.data.type === CoinType.eth ? this.openCancelEthDialog() : this.openCancelBtcDialog();
    dialogRef.afterClosed().subscribe(ok => {
      this.storeService.ready.subscribe(async () => {
        if (ok && ok.pin) {
          this.isLoading = true;
          try {
            const data = {
              orderId: order.id,
              status: 4
            };
            await this.swapService.cancelOrderExternalCoin(data).subscribe(async (response: any) => {
              if (response && response.errorCode === 1) {
                this.isLoading = false;
                this.notifierService.notify('error', this.i18nService.getTranslation('error_cancel_order'));
              }
              if (response && response.errorCode === 0) {
                const orderResponse = await this.swapService.getSwapOrderDetails(this.order.id);
                this.order = orderResponse.result;
                this.notifierService.notify('success', this.i18nService.getTranslation('cancel_swap_success'));
                this.isLoading = false;
              }
            });
          } catch (e) {
            if (isKeyDecryptionError(e)) {
              this.notifierService.notify('error', this.i18nService.getTranslation('wrong_pin'));
              this.isLoading = false;
            } else {
              console.warn(e);
              this.isLoading = false;
            }
          }
        }
      });
    });
  }

  openCancelEthDialog(): MatDialogRef<any> {
    return this.warnDialog.open(CancelOrderEthComponent, {
      width: '500px',
      data: {
        account: this.selectedAccount
      }
    });
  }

  openCancelBtcDialog(): MatDialogRef<any> {
    return this.warnDialog.open(CancelOrderBtcComponent, {
      width: '500px',
      data: {
        account: this.selectedAccount
      }
    });
  }


  openCancelDialog(asset, item): void {
    const openCancelDialogRef = this.openDialog();
    openCancelDialogRef.afterClosed().subscribe(ok => {
      this.storeService.ready.subscribe(async () => {
        if (ok && ok.pin) {
          this.isLoading = true;
          try {
            const data = {
              order: asset.transaction,
              fee: '0',
              publicKey: this.selectedAccount.keys.publicKey,
              deadline: '1440'
            };
            const {unsignedTransactionBytes} = asset.type === 'ASK' ? await this.swapService.cancelAskOrder(data) : await this.swapService.cancelBidOrder(data);
            const {transaction} = await signAndBroadcastTransaction(new ChainService({nodeHost: this.node}))({
              senderPrivateKey: this.getSendersSignPrivateKey(ok.pin, this.selectedAccount.keys),
              senderPublicKey: this.selectedAccount.keys.publicKey,
              unsignedHexMessage: unsignedTransactionBytes
            });
            if (transaction) {
              const dataCancel = new FormData();
              dataCancel.append('account', this.selectedAccount.account);
              dataCancel.append('accountRS', this.selectedAccount.accountRS);
              dataCancel.append('transactionId', transaction);
              dataCancel.append('orderId', asset.transaction);
              dataCancel.append('quantity', item.quantityQNT);
              dataCancel.append('price', item.priceNQT);
              dataCancel.append('type', asset.type);
              dataCancel.append('asset', asset.asset);

              await this.swapService.cancelOrder(dataCancel).subscribe(async (response: any) => {
                if (response && response.errorCode === 1) {
                  this.isLoading = false;
                  this.notifierService.notify('error', this.i18nService.getTranslation('error_cancel_order'));
                }
                if (response && response.errorCode === 0) {
                  const orderResponse = await this.swapService.getTFSTsOrderDetails(asset.id, this.selectedAccount.account);
                  this.order = orderResponse.result;
                  this.orderDetail = this.order.detail.sort(function (x, y) {
                    if (x.completeDate == null || y.completeDate == null) {
                      return -1;
                    }
                    return x.completeDate - y.completeDate;
                  });
                  this.notifierService.notify('success', this.i18nService.getTranslation('cancel_swap_success'));
                  this.isLoading = false;
                }
              });
            }
          } catch (e) {
            if (isKeyDecryptionError(e)) {
              this.notifierService.notify('error', this.i18nService.getTranslation('wrong_pin'));
              this.isLoading = false;
            } else {
              console.warn(e);
              this.isLoading = false;
            }
          }
        }
      });
    });
  }

  getSendersSignPrivateKey(pin: string, keys: Keys): string {
    const privateKey = decryptAES(keys.signPrivateKey, hashSHA256(pin));
    if (!privateKey) {
      throw new KeyDecryptionException();
    }
    return privateKey;
  }

  openDialog(): MatDialogRef<any> {
    return this.warnDialog.open(CancelTfstsOrderComponent, {
      width: '500px',
      data: {
        account: this.selectedAccount
      }
    });
  }

  getDate(timestamp): string {
    const time = ChainTime.fromChainTimestamp(Number(timestamp));
    return moment(time.getDate()).format('lll');
  }

  calculateTotalAmount(value1, value2): string {
    return (Amount.fromPlanck(value1).getRaw().plus(Amount.fromPlanck(value2).getRaw()).toString());
  }

}
