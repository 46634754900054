import {Router} from '@angular/router';
import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {Account} from '@fruitsjs/core';
import {Amount, BNBSymbol, BTCSymbol, CurrencySymbol, ETHSymbol} from '@fruitsjs/util';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import {I18nService} from '../../../layout/components/i18n/i18n.service';
import {UnsubscribeOnDestroy} from '../../../util/UnsubscribeOnDestroy';
import {delay, takeUntil} from 'rxjs/operators';
import {AccountBalances, getBalancesFromAccount} from '../../../util/balance';
import {MarketService} from '../market/market.service';
import {constants} from '../../../constants';
import {StoreService} from '../../../store/store.service';
import {Settings} from '../../../settings';
import {AccountService} from '../../../setup/account/account.service';
import {HttpClient, HttpParams} from '@angular/common/http';
import {interval, Subscription} from 'rxjs';
import {TRXSymbol, USDTSymbol} from '@fruitsjs/util/src';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'app-balance-chart',
  templateUrl: './balance-chart.component.html',
  styleUrls: ['./balance-chart.component.scss']
})
export class BalanceChartComponent extends UnsubscribeOnDestroy implements OnInit, OnChanges, AfterViewInit, OnDestroy {
  @ViewChild('myCanvas', {static: false}) canvas: ElementRef<HTMLCanvasElement>;
  @Input() public account: Account;
  settings: Settings;
  sub: Subscription;

  multiCoinUrl = environment.isMainNet ? constants.coinMarketCapURL : constants.multicoinTestURL;

  requestCurrency = constants.requestCurrency;

  currencyListFrts;
  currencyListCopy;
  currencySelectedFrts;
  currencyInputFrts;
  isSelectingFrts = false;
  isSelectingEth = false;
  isSelectingBtc = false;
  isSelectingUsdt = false;
  isSelectingTrxUsdt = false;
  isSelectingTrx = false;
  isSelectingBnbUsdt = false;
  isSelectingBnb = false;

  ethBalance = '0';
  btcBalance = '0';
  usdtBalance = '0';
  trxUsdtBalance = '0';
  trxBalance = '0';
  bnbBalance = '0';
  bnbUsdtBalance = '0';

  currencyListEth;
  currencyListBtc;
  currencyListUsdt;
  currencyListTrxUsdt;
  currencyListTrx;
  currencyListBnbUsdt;
  currencyListBnb;
  currencySelectedEth;
  currencySelectedBtc;
  currencySelectedUsdt;
  currencySelectedTrxUsdt;
  currencySelectedTrx;
  currencySelectedBnbUsdt;
  currencySelectedBnb;
  currencyInputEth;
  currencyInputBtc;
  currencyInputUsdt;
  currencyInputTrxUsdt;
  currencyInputTrx;
  currencyInputBnbUsdt;
  currencyInputBnb;

  priceFrts: CoinMarketPrice;
  priceEth: CoinMarketPrice;
  priceBtc: CoinMarketPrice;
  priceUsdt: CoinMarketPrice;
  priceTrxUsdt: CoinMarketPrice;
  priceTrx: CoinMarketPrice;
  priceBnbUsdt: CoinMarketPrice;
  priceBnb: CoinMarketPrice;

  unsubscriber = takeUntil(this.unsubscribeAll);

  transactionCount = 50;

  accountBalances: AccountBalances;
  private isMobile = false;

  constructor(private router: Router,
              private i18nService: I18nService,
              private marketService: MarketService,
              private breakpointObserver: BreakpointObserver,
              private storeService: StoreService,
              private accountService: AccountService,
              private http: HttpClient) {
    super();
    this.storeService.getSettings().then((settings: any) => {
      this.settings = settings;
    });
  }

  ngOnInit(): void {
    this.accountService.currentAccount
      .pipe(
        delay(1),
        takeUntil(this.unsubscribeAll)
      )
      .subscribe(async () => {
        this.getBalance();
      });

    this.breakpointObserver
      .observe(Breakpoints.Handset)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe(({matches}) => {
        this.isMobile = matches;
        this.updateChart();
      });

    this.i18nService.subscribe(() => {
      this.updateChart();
    }, null);

    this.currencyListFrts = constants.currencies.map(currency => this.requestCurrency.includes(currency.code) ? currency : undefined).filter(currency => currency !== undefined);
    this.currencySelectedFrts = this.settings && this.settings.currency && this.settings.currency.frts
      ? this.currencyListFrts.filter(item => item.code === this.settings.currency.frts)[0] : this.currencyListFrts[0];
    this.currencyListCopy = this.currencyListFrts;

    this.currencyListEth = constants.currencies.map(currency => this.requestCurrency.includes(currency.code) ? currency : undefined).filter(currency => currency !== undefined);
    this.currencySelectedEth = this.settings && this.settings.currency && this.settings.currency.eth
      ? this.currencyListEth.filter(item => item.code === this.settings.currency.eth)[0] : this.currencyListEth[0];

    this.currencyListBtc = constants.currencies.map(currency => this.requestCurrency.includes(currency.code) ? currency : undefined).filter(currency => currency !== undefined);
    this.currencySelectedBtc = this.settings && this.settings.currency && this.settings.currency.btc
      ? this.currencyListBtc.filter(item => item.code === this.settings.currency.btc)[0] : this.currencyListBtc[0];

    this.currencyListUsdt = constants.currencies.map(currency => this.requestCurrency.includes(currency.code) ? currency : undefined).filter(currency => currency !== undefined);
    this.currencySelectedUsdt = this.settings && this.settings.currency && this.settings.currency.usdt
      ? this.currencyListUsdt.filter(item => item.code === this.settings.currency.usdt)[0] : this.currencyListEth[0];

    this.currencyListTrxUsdt = constants.currencies.map(currency => this.requestCurrency.includes(currency.code) ? currency : undefined).filter(currency => currency !== undefined);
    this.currencySelectedTrxUsdt = this.settings && this.settings.currency && this.settings.currency.trxusdt
      ? this.currencyListTrxUsdt.filter(item => item.code === this.settings.currency.trxusdt)[0] : this.currencyListTrxUsdt[0];

    this.currencyListTrx = constants.currencies.map(currency => this.requestCurrency.includes(currency.code) ? currency : undefined).filter(currency => currency !== undefined);
    this.currencySelectedTrx = this.settings && this.settings.currency && this.settings.currency.trx
      ? this.currencyListTrx.filter(item => item.code === this.settings.currency.trx)[0] : this.currencyListTrx[0];

    this.currencyListBnb= constants.currencies.map(currency => this.requestCurrency.includes(currency.code) ? currency : undefined).filter(currency => currency !== undefined);
    this.currencySelectedBnb = this.settings && this.settings.currency && this.settings.currency.bnb
      ? this.currencyListBnb.filter(item => item.code === this.settings.currency.bnb)[0] : this.currencyListBnb[0];

    this.currencyListBnbUsdt= constants.currencies.map(currency => this.requestCurrency.includes(currency.code) ? currency : undefined).filter(currency => currency !== undefined);
    this.currencySelectedBnbUsdt = this.settings && this.settings.currency && this.settings.currency.bnbusdt
      ? this.currencyListBnbUsdt.filter(item => item.code === this.settings.currency.bnbusdt)[0] : this.currencyListBnbUsdt[0];

  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  getBalance(): void {
    if (this.account.multiKeys) {
      this.ethBalance = this.account.multiKeys.eth && this.account.multiKeys.eth.balance;
      this.btcBalance = this.account.multiKeys.btc && this.account.multiKeys.btc.balance;
      this.usdtBalance = this.account.multiKeys.eth && this.account.multiKeys.eth.usdtBalance;
      this.trxUsdtBalance = this.account.multiKeys.tron && this.account.multiKeys.tron.usdtBalance ? this.account.multiKeys.tron.usdtBalance : '0';
      this.trxBalance = this.account.multiKeys.tron && this.account.multiKeys.tron.balance ? this.account.multiKeys.tron.balance : '0';

      // balance bsc
      this.bnbBalance = this.account.multiKeys.bnb && this.account.multiKeys.bnb.balance ? this.account.multiKeys.bnb.balance : '0';
      this.bnbUsdtBalance = this.account.multiKeys.bnb && this.account.multiKeys.bnb.usdtBalance ? this.account.multiKeys.bnb.usdtBalance : '0';
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.updateChart();
  }

  public updateChart(): void {
    this.accountBalances = getBalancesFromAccount(this.account);
  }

  calculateValue(unitPrice = '0'): string {
    return this.splitNumber(Amount.fromPlanck(this.account.balanceNQT || '0').getRaw().multipliedBy(Amount.fromPlanck(unitPrice).getRaw()).dividedBy(1E8), 4);
  }

  calculateValueEth(unitPrice = '0'): string {
    return this.splitNumber(this.ethBalance ? Amount.fromPlanck(this.ethBalance || '0').getRaw().multipliedBy(Amount.fromPlanck(unitPrice).getRaw()) : '0', 4);
  }

  calculateValueBtc(unitPrice = '0'): string {
    return this.splitNumber(this.btcBalance ? Amount.fromPlanck(this.btcBalance || '0').getRaw().multipliedBy(Amount.fromPlanck(unitPrice).getRaw()) : '0', 4);
  }

  calculateValueUsdt(unitPrice = '0'): string {
    return this.splitNumber(this.usdtBalance ? Amount.fromPlanck(this.usdtBalance || '0').getRaw().multipliedBy(Amount.fromPlanck(unitPrice).getRaw()) : '0', 4);
  }

  calculateValueTrxUsdt(unitPrice = '0'): string {
    return this.splitNumber(this.trxUsdtBalance
      ? Amount.fromPlanck(this.trxUsdtBalance || '0').getRaw().multipliedBy(Amount.fromPlanck(unitPrice).getRaw()) : '0', 4);
  }

  calculateValueTrx(unitPrice = '0'): string {
    return this.splitNumber(this.trxBalance
      ? Amount.fromPlanck(this.trxBalance || '0').getRaw().multipliedBy(Amount.fromPlanck(unitPrice).getRaw()) : '0', 4);
  }

  calculateValueBnb(unitPrice = '0'): string {
    return this.splitNumber(this.bnbBalance
      ? Amount.fromPlanck(this.bnbBalance || '0').getRaw().multipliedBy(Amount.fromPlanck(unitPrice).getRaw()) : '0', 4);
  }

  calculateValueBnbUsdt(unitPrice = '0'): string {
    return this.splitNumber(this.bnbUsdtBalance
      ? Amount.fromPlanck(this.bnbUsdtBalance || '0').getRaw().multipliedBy(Amount.fromPlanck(unitPrice).getRaw()).toFixed(constants.BNB_DECIMAL).replace(/\.?0+$/, '').toString() : '0', 4);
  }

  splitNumber(numb, nbAfterComa): string {
    const number = numb.toString();
    if (!number.includes('.')) {
      return number;
    }
    const splicedValue = number.split('.');
    splicedValue[1] = splicedValue[1].trim().slice(0, nbAfterComa);
    return nbAfterComa > 0 ? splicedValue.join('.') : splicedValue[0];
  }

  async ngAfterViewInit(): Promise<void> {
    await this.getPriceFrts();
    if (this.account && this.account.multiKeys && this.account.multiKeys.eth && this.account.multiKeys.eth.balance) {
      await this.getPriceEth();
      await this.getPriceUsdt();
    }
    if (this.account && this.account.multiKeys && this.account.multiKeys.btc && this.account.multiKeys.btc.balance) {
      await this.getPriceBtc();
    }
    if (this.account && this.account.multiKeys && this.account.multiKeys.tron && this.account.multiKeys.tron.balance) {
      await this.getPriceTrx();
    }
    if (this.account && this.account.multiKeys && this.account.multiKeys.tron && this.account.multiKeys.tron.usdtBalance) {
      await this.getPriceTrxUsdt();
    }
    if (this.account && this.account.multiKeys && this.account.multiKeys.bnb && this.account.multiKeys.bnb.balance) {
      await this.getPriceBnb();
    }
    if (this.account && this.account.multiKeys && this.account.multiKeys.bnb && this.account.multiKeys.bnb.usdtBalance) {
      await this.getPriceBnbUsdt();
    }
    this.sub = interval(60000).subscribe(async () => {
      await this.getPriceFrts();
      if (this.ethBalance) {
        await this.getPriceEth();
      }
      if (this.btcBalance) {
        await this.getPriceBtc();
      }
      if (this.usdtBalance) {
        await this.getPriceUsdt();
      }
      if (this.trxUsdtBalance) {
        await this.getPriceTrxUsdt();
      }
      if (this.trxBalance) {
        await this.getPriceTrx();
      }
      if (this.bnbBalance) {
        await this.getPriceBnb();
      }
      if (this.bnbUsdtBalance) {
        await this.getPriceBnbUsdt();
      }
    });
    // this.marketService.createTicker(CurrencySymbol, this.currencySelectedFrts.code)
    //   .pipe(this.unsubscriber)
    //   .subscribe(async (response: CoinMarketPrice) => {
    //     this.priceFrts = response;
    //     if (!this.priceFrts.priceSelectedCurrency) {
    //       this.http.get('https://api.coingecko.com/api/v3/simple/price?ids=fruits&vs_currencies=' + this.currencySelectedFrts.code.toLowerCase()).subscribe((res: any) => {
    //         this.priceFrts.priceSelectedCurrency = res.fruits[this.currencySelectedFrts.code.toLowerCase()];
    //       });
    //     }
    //   });
    //
    // this.marketService.createTicker(ETHSymbol, this.currencySelectedEth.code)
    //   .pipe(this.unsubscriber)
    //   .subscribe((response: CoinMarketPrice) => {
    //     this.priceEth = response;
    //     if (!this.priceEth.priceSelectedCurrency) {
    //       this.http.get('https://api.coingecko.com/api/v3/simple/price?ids=ethereum&vs_currencies=' + this.currencySelectedEth.code.toLowerCase()).subscribe((res: any) => {
    //         this.priceEth.priceSelectedCurrency = res.ethereum[this.currencySelectedEth.code.toLowerCase()];
    //       });
    //     }
    //   });
    //
    // this.marketService.createTicker(BTCSymbol, this.currencySelectedBtc.code)
    //   .pipe(this.unsubscriber)
    //   .subscribe((response: CoinMarketPrice) => {
    //     this.priceBtc = response;
    //     if (!this.priceBtc.priceSelectedCurrency) {
    //       this.http.get('https://api.coingecko.com/api/v3/simple/price?ids=bitcoin&vs_currencies=' + this.currencySelectedBtc.code.toLowerCase()).subscribe((res: any) => {
    //         this.priceBtc.priceSelectedCurrency = res.bitcoin[this.currencySelectedBtc.code.toLowerCase()];
    //       });
    //     }
    //   });
  }

  formatCurrency(currency): string {
    if (currency) {
      const firstDigit = currency.match(/\d/);
      const symbol = currency.slice(0, firstDigit.index);
      const amount = currency.slice(firstDigit.index);
      return symbol + ' ' + amount;
    }

    return '';
  }

  filterCurrency(event): void {
    if (event.target.value) {
      this.currencyListFrts = this.currencyListCopy.filter(currency => currency.code.toLowerCase().includes(event.target.value.toLowerCase()));
    } else {
      this.currencyListFrts = this.currencyListCopy;
    }
  }

  filterCurrencyEth(event): void {
    if (event.target.value) {
      this.currencyListEth = this.currencyListCopy.filter(currency => currency.code.toLowerCase().includes(event.target.value.toLowerCase()));
    } else {
      this.currencyListEth = this.currencyListCopy;
    }
  }

  filterCurrencyBtc(event): void {
    if (event.target.value) {
      this.currencyListBtc = this.currencyListCopy.filter(currency => currency.code.toLowerCase().includes(event.target.value.toLowerCase()));
    } else {
      this.currencyListBtc = this.currencyListCopy;
    }
  }

  filterCurrencyUsdt(event): void {
    if (event.target.value) {
      this.currencyListUsdt = this.currencyListCopy.filter(currency => currency.code.toLowerCase().includes(event.target.value.toLowerCase()));
    } else {
      this.currencyListUsdt = this.currencyListCopy;
    }
  }

  filterCurrencyTrxUsdt(event): void {
    if (event.target.value) {
      this.currencyListTrxUsdt = this.currencyListCopy.filter(currency => currency.code.toLowerCase().includes(event.target.value.toLowerCase()));
    } else {
      this.currencyListTrxUsdt = this.currencyListCopy;
    }
  }

  filterCurrencyTrx(event): void {
    if (event.target.value) {
      this.currencyListTrx = this.currencyListCopy.filter(currency => currency.code.toLowerCase().includes(event.target.value.toLowerCase()));
    } else {
      this.currencyListTrx = this.currencyListCopy;
    }
  }

  filterCurrencyBnbUsdt(event): void {
    if (event.target.value) {
      this.currencyListBnbUsdt = this.currencyListCopy.filter(currency => currency.code.toLowerCase().includes(event.target.value.toLowerCase()));
    } else {
      this.currencyListBnbUsdt = this.currencyListCopy;
    }
  }

  filterCurrencyBnb(event): void {
    if (event.target.value) {
      this.currencyListBnb = this.currencyListCopy.filter(currency => currency.code.toLowerCase().includes(event.target.value.toLowerCase()));
    } else {
      this.currencyListBnb = this.currencyListCopy;
    }
  }

  formatBalance(balance): string {
    const intPart = balance.substr(0, balance.indexOf('.'));
    return intPart ? intPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',') + balance.substr(balance.indexOf('.'))
      : balance.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  async onCurrencyChange(event): Promise<void> {
    if (event && event.value) {
      this.isSelectingFrts = true;
      this.settings.currency.frts = event.value.code;
      await this.storeService.saveSettings(this.settings);
      await this.getPriceFrts();
    }
  }

  async onCurrencyChangeEth(event): Promise<void> {
    if (event && event.value) {
      this.isSelectingEth = true;
      this.settings.currency.eth = event.value.code;
      await this.storeService.saveSettings(this.settings);
      await this.getPriceEth();
    }
  }

  async onCurrencyChangeBtc(event): Promise<void> {
    if (event && event.value) {
      this.isSelectingBtc = true;
      this.settings.currency.btc = event.value.code;
      await this.storeService.saveSettings(this.settings);
      await this.getPriceBtc();
    }
  }

  async onCurrencyChangeUsdt(event): Promise<void> {
    if (event && event.value) {
      this.isSelectingUsdt = true;
      this.settings.currency.usdt = event.value.code;
      await this.storeService.saveSettings(this.settings);
      await this.getPriceUsdt();
    }
  }

  async onCurrencyChangeTrx(event): Promise<void> {
    if (event && event.value) {
      this.isSelectingTrx = true;
      this.settings.currency.trx = event.value.code;
      await this.storeService.saveSettings(this.settings);
      await this.getPriceTrx();
    }
  }
  async onCurrencyChangeTrxUsdt(event): Promise<void> {
    if (event && event.value) {
      this.isSelectingTrxUsdt = true;
      this.settings.currency.trxusdt = event.value.code;
      await this.storeService.saveSettings(this.settings);
      await this.getPriceTrxUsdt();
    }
  }

  async onCurrencyChangeBnb(event): Promise<void> {
    if (event && event.value) {
      this.isSelectingBnb = true;
      this.settings.currency.bnb = event.value.code;
      await this.storeService.saveSettings(this.settings);
      await this.getPriceBnb();
    }
  }
  async onCurrencyChangeBnbUsdt(event): Promise<void> {
    if (event && event.value) {
      this.isSelectingBnbUsdt = true;
      this.settings.currency.bnbusdt = event.value.code;
      await this.storeService.saveSettings(this.settings);
      await this.getPriceBnbUsdt();
    }
  }

  async getPriceFrts(): Promise<void> {
    if (this.currencySelectedFrts) {
      const params = new HttpParams()
        .set('source', CurrencySymbol)
        .set('destination', this.currencySelectedFrts.code);
      this.http.get(this.multiCoinUrl + '/fruits/converter/api', {
        params: params
      }).subscribe((response: any) => {
        if (response && response.errorCode === 0) {
          this.priceFrts = response.result;
          if (!this.priceFrts.priceSelectedCurrency) {
            this.http.get('https://api.coingecko.com/api/v3/simple/price?ids=fruits&vs_currencies=' + this.currencySelectedFrts.code.toLowerCase()).subscribe((res: any) => {
              this.priceFrts.priceSelectedCurrency = res.fruits[this.currencySelectedFrts.code.toLowerCase()];
            });
          }
        }
        this.isSelectingFrts = false;
      }, (e) => {
        console.warn(e);
        this.isSelectingFrts = false;
      });
    }
  }

  async getPriceEth(): Promise<void> {
    if (this.currencySelectedEth) {
      const params = new HttpParams()
        .set('source', ETHSymbol)
        .set('destination', this.currencySelectedEth.code);
      this.http.get(this.multiCoinUrl + '/fruits/converter/api', {
        params: params
      }).subscribe((response: any) => {
        if (response && response.errorCode === 0) {
          this.priceEth = response.result;
          if (!this.priceEth.priceSelectedCurrency) {
            this.http.get('https://api.coingecko.com/api/v3/simple/price?ids=ethereum&vs_currencies=' + this.currencySelectedEth.code.toLowerCase()).subscribe((res: any) => {
              this.priceEth.priceSelectedCurrency = res.ethereum[this.currencySelectedEth.code.toLowerCase()];
            });
          }
        }
        this.isSelectingEth = false;
      }, (e) => {
        console.warn(e);
        this.isSelectingEth = false;
      });
    }
  }

  async getPriceBnb(): Promise<void> {
    if (this.currencySelectedBnb) {
      const params = new HttpParams()
        .set('source', BNBSymbol)
        .set('destination', this.currencySelectedBnb.code);
      this.http.get(this.multiCoinUrl + '/fruits/converter/api', {
        params: params
      }).subscribe((response: any) => {
        if (response && response.errorCode === 0) {
          this.priceBnb = response.result;
          if (!this.priceBnb.priceSelectedCurrency) {
            this.http.get('https://api.coingecko.com/api/v3/simple/price?ids=binancecoin&vs_currencies=' + this.currencySelectedBnb.code.toLowerCase()).subscribe((res: any) => {
              this.priceBnb.priceSelectedCurrency = res.ethereum[this.currencySelectedBnb.code.toLowerCase()];
            });
          }
        }
        this.isSelectingBnb = false;
      }, (e) => {
        console.warn(e);
        this.isSelectingBnb = false;
      });
    }
  }


  async getPriceBtc(): Promise<void> {
    if (this.currencySelectedBtc) {
      const params = new HttpParams()
        .set('source', BTCSymbol)
        .set('destination', this.currencySelectedBtc.code);
      this.http.get(this.multiCoinUrl + '/fruits/converter/api', {
        params: params
      }).subscribe((response: any) => {
        if (response && response.errorCode === 0) {
          this.priceBtc = response.result;
          if (!this.priceBtc.priceSelectedCurrency) {
            this.http.get('https://api.coingecko.com/api/v3/simple/price?ids=bitcoin&vs_currencies=' + this.currencySelectedBtc.code.toLowerCase()).subscribe((res: any) => {
              this.priceBtc.priceSelectedCurrency = res.bitcoin[this.currencySelectedBtc.code.toLowerCase()];
            });
          }
        }
        this.isSelectingBtc = false;
      }, (e) => {
        console.warn(e);
        this.isSelectingBtc = false;
      });
    }
  }

  async getPriceUsdt(): Promise<void> {
    if (this.currencySelectedUsdt) {
      const params = new HttpParams()
        .set('source', USDTSymbol)
        .set('destination', this.currencySelectedUsdt.code);
      this.http.get(this.multiCoinUrl + '/fruits/converter/api', {
        params: params
      }).subscribe((response: any) => {
        if (response && response.errorCode === 0) {
          this.priceUsdt = response.result;
          if (!this.priceUsdt.priceSelectedCurrency) {
            this.http.get('https://api.coingecko.com/api/v3/simple/price?ids=usd&vs_currencies=' + this.currencySelectedUsdt.code.toLowerCase()).subscribe((res: any) => {
              this.priceUsdt.priceSelectedCurrency = res.ethereum[this.currencySelectedUsdt.code.toLowerCase()];
            });
          }
        }
        this.isSelectingUsdt = false;
      }, (e) => {
        console.warn(e);
        this.isSelectingUsdt = false;
      });
    }
  }
  
  async getPriceBnbUsdt(): Promise<void> {
    if (this.currencySelectedBnbUsdt) {
      const params = new HttpParams()
        .set('source', USDTSymbol)
        .set('destination', this.currencySelectedBnbUsdt.code);
      this.http.get(this.multiCoinUrl + '/fruits/converter/api', {
        params: params
      }).subscribe((response: any) => {
        if (response && response.errorCode === 0) {
          this.priceBnbUsdt = response.result;
          if (!this.priceBnbUsdt.priceSelectedCurrency) {
            this.http.get('https://api.coingecko.com/api/v3/simple/price?ids=usd&vs_currencies=' + this.currencySelectedBnbUsdt.code.toLowerCase()).subscribe((res: any) => {
              this.priceBnbUsdt.priceSelectedCurrency = res.ethereum[this.currencySelectedBnbUsdt.code.toLowerCase()];
            });
          }
        }
        this.isSelectingBnbUsdt = false;
      }, (e) => {
        console.warn(e);
        this.isSelectingBnbUsdt = false;
      });
    }
  }

  async getPriceTrxUsdt(): Promise<void> {
    if (this.currencySelectedTrxUsdt) {
      const params = new HttpParams()
        .set('source', USDTSymbol)
        .set('destination', this.currencySelectedTrxUsdt.code);
      this.http.get(this.multiCoinUrl + '/fruits/converter/api', {
        params: params
      }).subscribe((response: any) => {
        if (response && response.errorCode === 0) {
          this.priceTrxUsdt = response.result;
          if (!this.priceTrxUsdt.priceSelectedCurrency) {
            this.http.get('https://api.coingecko.com/api/v3/simple/price?ids=usd&vs_currencies=' + this.currencySelectedTrxUsdt.code.toLowerCase()).subscribe((res: any) => {
              this.priceTrxUsdt.priceSelectedCurrency = res.ethereum[this.currencySelectedTrxUsdt.code.toLowerCase()];
            });
          }
        }
        this.isSelectingTrxUsdt = false;
      }, (e) => {
        console.warn(e);
        this.isSelectingTrxUsdt = false;
      });
    }
  }

  async getPriceTrx(): Promise<void> {
    if (this.currencySelectedTrx) {
      const params = new HttpParams()
        .set('source', TRXSymbol)
        .set('destination', this.currencySelectedTrx.code);
      this.http.get(this.multiCoinUrl + '/fruits/converter/api', {
        params: params
      }).subscribe((response: any) => {
        if (response && response.errorCode === 0) {
          this.priceTrx = response.result;
        }
        this.isSelectingTrx = false;
      }, (e) => {
        console.warn(e);
        this.isSelectingTrx = false;
      });
    }
  }

}

export interface CoinMarketPrice {
  priceBtc: string;
  priceSelectedCurrency: string;
}
