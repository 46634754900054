import {FuseNavigation} from '@fuse/types';

export const navigation: FuseNavigation[] = [
  {
    id: 'dashboard',
    title: 'Dashboard',
    type: 'item',
    icon: 'icon-dashboard.png',
    url: '/dashboard'
  },
  {
    id: 'myTokens',
    title: 'My Tokens',
    type: 'item',
    icon: 'icon-assets.png',
    url: 'mytokens'
  },
  {
    id: 'aliases',
    title: 'NFTs',
    type: 'item',
    icon: 'icon-nfts.png',
    url: 'nfts',
    fullAccountOnly: true
  },
  {
    id: 'transactions',
    title: 'Transactions',
    type: 'group',
    children: [
      {
        id: 'transactions_frts_only',
        title: 'Transactions',
        type: 'item',
        icon: 'icon-transaction.png',
        url: '/frts-transactions'
      },
      {
        id: 'transactions_multi',
        title: 'Transactions',
        type: 'collapsable',
        icon: 'icon-transaction.png',
        children: [
          {
            id: 'frts_transactions',
            title: 'FRUITS',
            type: 'item',
            icon: 'icon-frts.png',
            url: '/frts-transactions'
          },
          {
            id: 'btc_transactions',
            title: 'Bitcoin',
            type: 'item',
            icon: 'icon-btc.png',
            url: '/btc-transactions'
          },
          {
            id: 'eth_transactions',
            title: 'Ethereum',
            type: 'item',
            icon: 'icon-eth.png',
            url: '/eth-transactions'
          },
          {
            id: 'usdt_transactions',
            title: 'Tether USD ( ERC20 )',
            type: 'item',
            icon: 'icon-usdt.png',
            url: '/usdt-transactions'
          },
          {
            id: 'trx_transactions',
            title: 'Tron',
            type: 'item',
            icon: 'icon-trx.png',
            url: '/trx-transactions'
          },
          {
            id: 'trx_usdt_transactions',
            title: 'Tether USD ( TRC20 )',
            type: 'item',
            icon: 'icon-trx-usdt.png',
            url: '/trx-usdt-transactions'
          },
          {
            id: 'bnb_transactions',
            title: 'BNB',
            type: 'item',
            icon: 'icon-bnb.png',
            url: '/bnb-transactions'
          },
          {
            id: 'bnb_usdt_transactions',
            title: 'Tether USD ( BEP20 )',
            type: 'item',
            icon: 'icon-bnbusdt.png',
            url: '/bnb-usdt-transactions'
          },
        ]
      },
      {
        id: 'send_amount',
        title: 'Send Amount',
        type: 'item',
        icon: 'icon-send.png',
        url: '/send',
        fullAccountOnly: true
      },
      {
        id: 'request_amount',
        title: 'Request Amount',
        type: 'item',
        icon: 'icon-request.png',
        url: '/request'
      },
      // {
      //   id: 'smart_contracts',
      //   title: 'Smart Contracts',
      //   type: 'item',
      //   icon: 'icon-ats.png',
      //   url: '/contracts'
      // },
      {
        id: 'swap',
        title: 'Swap',
        type: 'collapsable',
        icon: 'icon-swap.png',
        fullAccountOnly: true,
        children: [
          {
            id: 'swap_token',
            title: 'Juice DEX',
            type: 'item',
            icon: 'icon-juice-dex.png',
            url: '/swap-tokens',
            fullAccountOnly: true
          },
          {
            id: 'swap_history_tfsts',
            title: 'Juice DEX (TFSTs) History',
            type: 'item',
            icon: 'icon-orders.png',
            url: '/juice-tfsts-history',
            fullAccountOnly: true
          }
          ,
          {
            id: 'swap_history_btc',
            title: 'Juice DEX (BTC) History',
            type: 'item',
            icon: 'icon-orders.png',
            url: '/juice-btc-history',
            fullAccountOnly: true
          }
          ,
          {
            id: 'swap_history_eth',
            title: 'Juice DEX (ETH) History',
            type: 'item',
            icon: 'icon-orders.png',
            url: '/juice-eth-history',
            fullAccountOnly: true
          },
          {
            id: 'swap_history_erc',
            title: 'Juice DEX (ERC20) History',
            type: 'item',
            icon: 'icon-orders.png',
            url: '/juice-erc-history',
            fullAccountOnly: true
          },
          {
            id: 'swap_history_trc',
            title: 'Juice DEX (TRC20) History',
            type: 'item',
            icon: 'icon-orders.png',
            url: '/juice-trc-history',
            fullAccountOnly: true
          },
          {
            id: 'swap_history_bep',
            title: 'Juice DEX (BEP20) History',
            type: 'item',
            icon: 'icon-orders.png',
            url: '/juice-bep-history',
            fullAccountOnly: true
          }
        ]
      },
      {
        id: 'donation',
        title: 'Donation',
        type: 'item',
        icon: 'icon-heart.png',
        url: 'https://station.fwallet.net',
        externalUrl: true,
        openInNewTab: true,
        fullAccountOnly: true
      },
      // {
      //   id: 'token-exchange',
      //   title: 'Token Exchange',
      //   type: 'item',
      //   icon: 'show_chart',
      //   url: 'exchange'
      // },
    ]
  },
  {
    id: 'account',
    title: 'Account',
    type: 'group',
    children: [
      {
        id: 'info',
        title: 'Account Info',
        type: 'item',
        icon: 'icon-info.png',
        url: '/set-account-info'
      },
      {
        id: 'messages',
        title: 'Messages',
        type: 'item',
        icon: 'icon-message.png',
        url: '/messages',
        fullAccountOnly: true
      },
      // {
      //   id: 'notifications',
      //   title: 'Notifications',
      //   type: 'item',
      //   icon: 'icon-notification.png',
      //   url: '/notifications',
      //   fullAccountOnly: true
      // },
      // {
      //   id: 'banner',
      //   title: 'Banner',
      //   type: 'item',
      //   icon: 'icon-banner.png',
      //   url: '/banner',
      //   fullAccountOnly: true
      // },
      // {
      //   id: 'aliases',
      //   title: 'Aliases',
      //   type: 'item',
      //   icon: 'icon-aliases.png',
      //   url: '/aliases',
      // },
      // {
      //   id: 'nfts',
      //   title: 'nfts',
      //   type: 'item',
      //   icon: 'icon-my-nfts.png',
      //   url: '/my-nfts',
      //   fullAccountOnly: true
      // },
      // {
      //   id: 'aliases_list',
      //   title: 'Aliases List',
      //   type: 'item',
      //   icon: 'icon-wallet1.png',
      //   url: '/nfts'
      // },
      // {
      //   id: 'nft_collection',
      //   title: 'NFT Collection',
      //   type: 'item',
      //   icon: 'icon-collection.png',
      //   url: '/collection'
      // },
      // {
      //   id: 'nft_utility',
      //   title: 'NFT Utility',
      //   type: 'item',
      //   icon: 'icon-utility.png',
      //   url: '/utility'
      // },
      // {
      //   id: 'assets',
      //   title: 'Assets',
      //   type: 'item',
      //   icon: 'icon-assets.png',
      //   url: '/assets'
      // },
    ]

  },
  {
    id: 'defi',
    title: 'DeFi',
    type: 'group',
    children: [
      {
        id: 'defi',
        title: 'DeFi',
        type: 'collapsable',
        icon: 'icon-defi.png',
        children: [
          {
            id: 'staking',
            title: 'Staking',
            type: 'item',
            url: 'staking',
            icon: 'icon-staking.png'
          },
          {
            id: 'lending',
            title: 'Lending',
            type: 'item',
            url: 'lending',
            icon: 'icon-lending.png'
          },
          {
            id: 'farming',
            title: 'Yield Farming',
            type: 'item',
            url: 'farming',
            icon: 'icon-yield.png'
          }
        ]
      },
      {
        id: 'defi_history',
        title: 'DeFi History',
        type: 'collapsable',
        icon: 'icon-history-defi.png',
        children: [
          {
            id: 'staking_history',
            title: 'Staking History',
            type: 'item',
            url: 'staking-history',
            icon: 'icon-staking.png'
          },
          {
            id: 'lending_history',
            title: 'Lending History',
            type: 'item',
            url: 'lending-history',
            icon: 'icon-lending.png'
          },
          {
            id: 'farming_history',
            title: 'Farming History',
            type: 'item',
            url: 'farming-history',
            icon: 'icon-yield.png'
          },
        ]
      }
    ]
  },
  {
    id: 'voting',
    title: 'Voting',
    type: 'group',
    children: [
      {
        id: 'overview',
        title: 'Overview',
        type: 'item',
        icon: 'icon-proposals-overview.png',
        url: '/overview'
      },
      {
        id: 'my_proposals',
        title: 'My Proposals',
        type: 'item',
        icon: 'icon-my-proposals.png',
        url: '/my-proposals'
      },
    ]
  },
  {
    id: 'mining',
    title: 'Mining',
    type: 'group',
    fullAccountOnly: true,
    children: [
      {
        id: 'fertilizer',
        title: 'Fertilizer',
        type: 'item',
        icon: 'icon-commitment.png',
        url: '/fertilizer'
      },
      {
        id: 'reward_recipient_assignment',
        title: 'Reward Assignment',
        type: 'item',
        icon: 'icon-joinpool.png',
        url: '/set-reward-recipient'
      },
    ]
  },
  {
    id: 'network',
    title: 'Network',
    type: 'group',
    children: [
      {
        id: 'blocks',
        title: 'Blocks',
        type: 'item',
        icon: 'icon-block.png',
        url: 'blocks'
      },
      {
        id: 'peers',
        title: 'Peers',
        type: 'item',
        icon: 'icon-peers.png',
        url: 'peers'
      }
    ]
  },
  {
    id: 'smile/tfsts',
    title: 'SMILE / TFSTs',
    type: 'group',
    children: [
      // {
      //   id: 'tokenExchange',
      //   title: 'Token Exchange',
      //   type: 'item',
      //   icon: 'show_chart',
      //   url: 'asset-exchange'
      // },
      {
        id: 'transferHistory',
        title: 'Transfer History',
        type: 'item',
        icon: 'icon-transfer.png',
        url: 'transfer-history'
      },

      // {
      //   id: 'openOrders',
      //   title: 'Open Orders',
      //   type: 'item',
      //   icon: 'pause',
      //   url: 'orders'
      // },
      {
        id: 'issueToken',
        title: 'Issue Token',
        type: 'item',
        icon: 'icon-issue.png',
        url: '/issue-token',
        fullAccountOnly: true
      },
      {
        id: 'issue_nft',
        title: 'Issue NFT',
        type: 'item',
        icon: 'icon-issue-nft1.png',
        url: '/issue-nft',
        fullAccountOnly: true
      },
      // {
      //   id: 'create_utility_nft',
      //   title: 'Create Utility NFT',
      //   type: 'item',
      //   icon: 'icon-create-utility.png',
      //   url: '/nft/utility/create',
      //   fullAccountOnly: true
      // },
      // {
      //   id: 'payoutDividends',
      //   title: 'Payout Dividends',
      //   type: 'item',
      //   icon: 'groups',
      //   url: '/asset-payout'
      // },
      // {
      //   id: 'swap_orders',
      //   title: 'Swap Tokens',
      //   type: 'item',
      //   icon: 'icon-transfer.png',
      //   url: 'swap'
      // },
    ]
  },

];
